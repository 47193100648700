import {atom} from 'recoil'

export const urlState = atom({
    key: "urlState",
    // default: 'http://172.18.17.210:11000/'
    default: 'https://api.tekmil-tm.com/'
})

export const tokenTemp = atom({
    key: "tokenTemp",
    default: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiMWRmNjY4YmYtYWU2OS00YmY1LThkNjMtNTUwYjVkZTk4MDg4IiwidXNlcl9uYW1lIjoiTGV5bGkiLCJjcmVhdGVkIjoiMjAyMi0wOS0yOFQxNDozNzo0My45Nzc3MzEiLCJleHAiOjE2NjQ0NjIyNjN9.CZGMObmcH2mAqVP_6LE0feAhCK_1Uq8KCUGVZrFp_xrgFDnwgC-ZXDrcviqmSet7tXbTatFUOoGPZLMXrUcaGRC-bb7_6DSgNDQxODV3NDLhRQw5U0oPTRXkr9nyOBCltWW9sMo9HOlC3HxSC-bigickp8ZnxWAbUdatozlhs2M9qNr0JknqCVMad9NTZJ9Ad-mbWgckdKpzYySnnAjF-fmMMOIEFCcRCyaBrS70Pt4mmray1rHVi5qvKvzwp4yg4A235AEETTXhNKf7KAqm95Sh7ymHHqqyrBfotCzSQWhYmPhlvkr5C4NIcX52nI9H6qb9dOpG6bqJC2cdKHkovkDSZ3qXWOUQOhtY87bIkcJWU02MzdDyO7p8MFKM03yuMAmfD7N_kMbMTb4vPSuTlkEgCTvcEsIdLhzCt9QZQ01frVSFIGQv-Rj2t5J4TqpKqAMLD9RSFyTqQf8U_ZpYio-WLKL-WZUTWIfwhIe1wCLvxpwlR2_1n91HMDXQOVqsmMwpc57JbhNY45U8tG_QmIGS7UoFMtAgXf1WwKDE7ftZCzN-E4WbsdfvX3fgrEItbNhDEwynP5wiCm1N97osz0H90veSNgQPl99x9nfJps37pdmy-RSd1JGEMZ4HuxegQZrswvlKi6CTzdZojn_PGzd16pvxmiSAfVfniPdbd3E'
})

export const tokenState = atom({
    key: 'tokenState',
    default: undefined
})

export const isLoggedState = atom({
    key: 'isLoggedState',
    default: false
})
